import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Api } from 'projects/visitor-app/src/app/services/api';
import { CurrentVisitorAccount } from 'projects/visitor-app/src/app/services/current-visitor-account';
import { Globals } from 'projects/visitor-app/src/app/services/globals';

@Component({
    selector: 'visit-results',
    templateUrl: './visit-results.component.html',
    styleUrls: ['./visit-results.component.scss']
})
export class VisitResultsComponent implements OnInit {
    @Input()
    visitResult;

    @Input()
    clientId;

    constructor(private api: Api, private router: Router, private globals: Globals, private currentVisitorAccount: CurrentVisitorAccount) {

    }


    async ngOnInit() {

    }

}
