import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedGlobals } from './shared-globals';


@Injectable()
export class Api {
    constructor(private http: HttpClient, private sharedGlobals: SharedGlobals) {

    }

    public requester = (baseUrl: string = '') => {
        let http = this.http;
        return {
            get: function <T>(url: string) {
                return http.get(`${baseUrl}${url}`, {
                    withCredentials: true
                }).toPromise() as Promise<T>
            },
            post: function <T>(url: string, data: any) {
                return http.post(`${baseUrl}${url}`, data, {
                    withCredentials: true
                }).toPromise() as Promise<T>
            },
            put: function <T>(url: string, data: any) {
                return http.put(`${baseUrl}${url}`, data, {
                    withCredentials: true
                }).toPromise() as Promise<T>
            },
            patch: function <T>(url: string, data: any) {
                return http.patch(`${baseUrl}${url}`, data, {
                    withCredentials: true
                }).toPromise() as Promise<T>
            },
            delete: function <T>(url: string) {
                return http.delete(`${baseUrl}${url}`, {
                    withCredentials: true
                }).toPromise() as Promise<T>
            }
        }
    }

    private baseUrlRequester = (baseUrl: string) => {
        return this.requester(`${this.sharedGlobals.baseUrl}${baseUrl}`);
    }

    public base = () => {
        return this.baseUrlRequester('/api');
    }

    public clientUrl = (clientId: string, url = '') => {
        return `/api/client/${clientId}${url}`;
    }

    public publicClientUrl = (clientId: string, url = '') => {
        return `/api/public/clients/${clientId}${url}`;
    }

    public client = (clientId: string) => {
        return this.baseUrlRequester(this.clientUrl(clientId));
    }

    public publicClient = (clientId: string) => {
        return this.baseUrlRequester(this.publicClientUrl(clientId));
    }


}