import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Api } from 'projects/visitor-app/src/app/services/api';
import { CurrentVisitorAccount } from 'projects/visitor-app/src/app/services/current-visitor-account';
import { Globals } from 'projects/visitor-app/src/app/services/globals';
import { environment } from 'projects/visitor-app/src/environments/environment';

@Component({
    selector: 'visit-history-entry',
    templateUrl: './visit-history-entry.component.html',
    styleUrls: ['./visit-history-entry.component.scss']
})
export class VisitHistoryEntryComponent implements OnInit {
    @Input()
    historyEntry;
    bookingReferenceUrl;
    viewingResults = false;
    visitResults: any[];
    constructor(private api: Api, private router: Router, private globals: Globals, private currentVisitorAccount: CurrentVisitorAccount) {

    }


    async ngOnInit() {

        if (this.historyEntry.data.bookingId) {
            this.bookingReferenceUrl = `${environment.bookingBaseUrl}/api/public/clients/${this.historyEntry.clientId}/bookings/${this.historyEntry.data.bookingId}/ticket`
        }
        else if (this.historyEntry.data.resourceBookingId) {
            this.bookingReferenceUrl = `${environment.bookingBaseUrl}/api/public/clients/${this.historyEntry.clientId}/resource-bookings/${this.historyEntry.data.resourceBookingId}/ticket`
        }

    }

    async viewResults() {
        this.visitResults = [];
        for (let visitResultId of this.historyEntry.data.visitResultIds) {
            let visitResult = await this.api.client(this.historyEntry.clientId).get<any>(`/visit-results/${visitResultId}`);
            this.visitResults.push(visitResult);
        }

        this.viewingResults = true;
    }

}
