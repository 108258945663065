// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iframe {
  width: 100%;
  border: 0px;
  height: 1100px;
}

.bowlit {
  max-width: 100px;
}`, "",{"version":3,"sources":["webpack://./projects/shared-app/src/app/components/third-party/bowlit/bowlit-results.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/shared-app/src/app/components/third-party/bowlit/bowlit-results.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,WAAA;EACA,cAAA;ACCF;;ADEA;EACE,gBAAA;ACCF","sourcesContent":["iframe{\r\n  width: 100%;\r\n  border: 0px;\r\n  height: 1100px;\r\n}\r\n\r\n.bowlit{\r\n  max-width:100px;\r\n}\r\n","iframe {\n  width: 100%;\n  border: 0px;\n  height: 1100px;\n}\n\n.bowlit {\n  max-width: 100px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
