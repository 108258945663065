import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Globals } from './globals';

@Injectable()
export class Api {
    constructor(private http: HttpClient, private globals: Globals) {

    }

    private requester = (baseUrl: string) => {
        let http = this.http;
        return {
            get: function <T>(url: string) {
                return http.get(`${environment.baseUrl}${baseUrl}${url}`, {
                    withCredentials: true
                }).toPromise() as Promise<T>
            },
            post: function <T>(url: string, data: any) {
                return http.post(`${environment.baseUrl}${baseUrl}${url}`, data, {
                    withCredentials: true
                }).toPromise() as Promise<T>
            },
            put: function <T>(url: string, data: any) {
                return http.put(`${environment.baseUrl}${baseUrl}${url}`, data, {
                    withCredentials: true
                }).toPromise() as Promise<T>
            },
            patch: function <T>(url: string, data: any) {
                return http.patch(`${environment.baseUrl}${baseUrl}${url}`, data, {
                    withCredentials: true
                }).toPromise() as Promise<T>
            },
            delete: function <T>(url: string) {
                return http.delete(`${environment.baseUrl}${baseUrl}${url}`, {
                    withCredentials: true
                }).toPromise() as Promise<T>
            }
        }
    }

    public base = () => {
        return this.requester('/api');
    }

    public client = (clientId: string) => {
        return this.requester('/api/public/clients/' + clientId);
    }

    public visitor = () => {
        return this.requester('/api/visitor');
    }


}