import { Injectable } from '@angular/core';
import { Api } from './api';
import { Router } from '@angular/router';
import { Globals } from './globals';
import { IVisitorAccount } from '../interfaces/visitor-account';



let currentVisitorAccount: IVisitorAccount | null = null;

@Injectable()
export class CurrentVisitorAccount {

    get visitorAccount(){
        return currentVisitorAccount;
    }
    constructor(private api: Api, private router: Router, private globals: Globals) {

    }

    public async get(): Promise<IVisitorAccount | null> {
        if (currentVisitorAccount)
            return currentVisitorAccount;
        else {
            try {
                currentVisitorAccount = await this.api.visitor().get<IVisitorAccount>('/me');
            }
            catch (error) {
                // If unauthorized, redirect to login
                if (error.status == 401) {
                    localStorage.removeItem('visitorAccountAccessToken');
                    this.router.navigateByUrl('/login');
                }
                else {
                    console.log(error);
                }
            }
            return currentVisitorAccount;
        }
    }


}