import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';

@Injectable()
export class Globals {
    baseUrl?: string = environment.baseUrl;
    locale: string;
    visitorAccountId:string;
    currentVisitorAccount: any;
}
