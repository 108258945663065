import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { IPrisonIslandCell, IPrisonIslandParticipant, IPrisonIslandVisitResult } from '../../../../../../../../../../common/common-third-party/prison-island/interfaces';
import { Api } from '../../../services/api';
import { TranslateHelper } from '../../../services/translation-helper';
import { Utility } from '../../../services/utility';

interface ICellResultData {
    cellName: string,
    cellPercent: number,
    cellTimeslots: {
        type: 'none' | 'first' | 'improvement' | 'failed' | 'max',
        points: number
    }[]
}

@Component({
    selector: 'shared-prison-island-results',
    templateUrl: './prison-island-results.component.html',
    styleUrls: ['./prison-island-results.component.scss']
})
export class PrisonIslandResultComponent {
    @Input()
    clientId: string;

    @Input()
    result: IPrisonIslandVisitResult;


    teamResults: {
        name: string,
        participants: { name: string }[],
        cellResults: ICellResultData[],
        points: number,
        startTime: string,
        endTime: string,
        cellVisits: number
    }[];

    cellCount: number;
    constructor(private translateHelper: TranslateHelper, private translate: TranslateService, private api: Api, public utility: Utility) {
        this.translate.currentLang = '';
        this.translate.setDefaultLang('en');
        this.translate.use(navigator.language);
        //translateHelper.init();

        //  this.result.heats[0].result.participants[0].gapToFirst
    }

    getParticipantsText(participants: any[]) {
        return participants.map(p => p.name).join(', ');
    }


    async ngOnInit() {
        let cells = await this.api.publicClient(this.clientId).get<any>('/third-party/prison-island/cells');
        let cellsById: { [id: string]: IPrisonIslandCell } = {};
        for (let cell of cells) {
            cellsById[String(cell.cellId)] = cell;
        }

        this.teamResults = [];
        this.cellCount = cells.length;

        for (let team of this.result.teams) {
            let cellResults: { [cellId: string]: ICellResultData } = {}
            let cellBestPoints: { [cellId: string]: number } = {}
            let startTime;
            let endTime;
            let cellVisits = 0;
            team.cellResults.sort((a, b) => {
                return moment(a.startTime).valueOf() - moment(b.startTime).valueOf()
            })

            for (let cellResult of team.cellResults) {
                if (!startTime)
                    startTime = moment(cellResult.startTime).format('HH:mm');
                console.log(moment(cellResult.startTime).format('HH:mm'));
                if (!cellResults[String(cellResult.cellId)]) {
                    cellResults[String(cellResult.cellId)] = {
                        cellName: cellsById[String(cellResult.cellId)].name,
                        cellPercent: 0,
                        cellTimeslots: []
                    }
                }
                endTime = moment(cellResult.startTime).add(cellResult.seconds, 'seconds').format('HH:mm');
            }

            for (let cellResult of team.cellResults) {
                for (let cellId of Object.keys(cellResults)) {
                    if (cellId != String(cellResult.cellId)) {
                        cellResults[String(cellId)].cellTimeslots.push({
                            type: 'none',
                            points: 0
                        });
                    }
                }

                // FIRST RESULT
                if (typeof (cellBestPoints[String(cellResult.cellId)]) == 'undefined') {
                    if (cellResult.points == cellsById[String(cellResult.cellId)].maxPoints) {
                        cellResults[String(cellResult.cellId)].cellTimeslots.push({
                            type: 'max',
                            points: cellResult.points
                        });
                    } else {
                        cellResults[String(cellResult.cellId)].cellTimeslots.push({
                            type: 'first',
                            points: cellResult.points
                        });
                    }

                    cellBestPoints[String(cellResult.cellId)] = cellResult.points;
                }
                // IMPROVED RESULT
                else if (cellBestPoints[String(cellResult.cellId)] < cellResult.points) {
                    if (cellResult.points == cellsById[String(cellResult.cellId)].maxPoints) {
                        cellResults[String(cellResult.cellId)].cellTimeslots.push({
                            type: 'max',
                            points: cellResult.points
                        });
                    } else {
                        cellResults[String(cellResult.cellId)].cellTimeslots.push({
                            type: 'improvement',
                            points: cellResult.points
                        });
                    }
                    cellBestPoints[String(cellResult.cellId)] = cellResult.points;
                }
                // FAILED RESULT
                else {
                    cellResults[String(cellResult.cellId)].cellTimeslots.push({
                        type: 'failed',
                        points: cellResult.points
                    });
                }

                cellVisits++;
                cellResults[String(cellResult.cellId)].cellPercent = Math.floor((cellBestPoints[String(cellResult.cellId)] / cellsById[String(cellResult.cellId)].maxPoints) * 100);
            }

            console.log(cellVisits);

            let points = 0;
            for (let cellId of Object.keys(cellBestPoints)) {
                points += cellBestPoints[cellId];
            }

            this.teamResults.push({
                name: team.name,
                participants: team.participants,
                cellResults: Object.values(cellResults),
                points: points,
                startTime: startTime,
                endTime: endTime,
                cellVisits: cellVisits
            });

        }

        this.teamResults.sort((a, b) => {
            return b.points - a.points;
        })
    }

    ngAfterViewInit(): void {

    }



}
