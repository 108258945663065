import { Component, OnInit } from '@angular/core';
import { Api } from '../../services/api';
import { Router } from '@angular/router';
import { Globals } from '../../services/globals';
import { CurrentVisitorAccount } from '../../services/current-visitor-account';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss', '../login/login.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    email: string = '';
    initialized = false;
    constructor(private api: Api, private router: Router, private globals: Globals, private currentVisitorAccount: CurrentVisitorAccount) {

    }


    async ngOnInit() {
        var video = document.querySelector('video');
        video.muted = true;
        video.playbackRate = 0.8;
        video.play();
    }

    async initializeReset() {

        let result = await this.api.visitor().post<any>('/login/reset-password/initialize', {
            email: this.email
        });
        if (result.succeeded) {
            this.initialized = true;
        } else {

        }

    }
}
