import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';



@Injectable()
export class TranslateHelper {

    constructor(private translate: TranslateService) {

    }

    public async init() {
        this.translate.setDefaultLang('en');
        this.translate.use(navigator.language);
    }


}