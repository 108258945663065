import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable()
export class SharedGlobals {
    public baseUrl;
    constructor() {
        this.baseUrl = environment.baseUrl;
    }
}