// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2,
h3 {
  margin: 0px;
}

h3 {
  font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./projects/visitor-app/src/app/components/me/history/visit-history-entry/visit-history-entry.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/visitor-app/src/app/components/me/history/visit-history-entry/visit-history-entry.component.scss"],"names":[],"mappings":"AAAA;;EAEI,WAAA;ACCJ;;ADEA;EACI,mBAAA;ACCJ","sourcesContent":["h2,\r\nh3 {\r\n    margin: 0px;\r\n}\r\n\r\nh3{\r\n    font-weight: normal;\r\n}\r\n","h2,\nh3 {\n  margin: 0px;\n}\n\nh3 {\n  font-weight: normal;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
