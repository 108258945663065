import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { CurrentVisitorAccount } from './services/current-visitor-account';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private currentVisitorAccount: CurrentVisitorAccount, private router: Router, private translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use(navigator.language);
    moment.locale(navigator.language);
  }

  async ngOnInit() {
    let visitorAccount = await this.currentVisitorAccount.get();
    if (visitorAccount)
      this.router.navigateByUrl('/me');
    else
      this.router.navigateByUrl('/login');
  }
}
